import { Dice } from "../../../../src/components/Dice";
import { CoinFlip } from "../../../../src/components/CoinFlip";
import { Title } from "../../../../src/components/Common/Title";
import { Drawing } from "../../../../src/components/Drawing";
import * as React from 'react';
export default {
  Dice,
  CoinFlip,
  Title,
  Drawing,
  React
};