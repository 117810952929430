import { Dice } from "../../../../src/components/Dice";
import { CoinFlip } from "../../../../src/components/CoinFlip";
import { Title } from "../../../../src/components/Common/Title";
import { Drawing } from "../../../../src/components/Drawing";
import Image1 from "../../../../src/workshops/generative-systems-02-14/001.png";
import Image2 from "../../../../src/workshops/generative-systems-02-14/002.png";
import Image3 from "../../../../src/workshops/generative-systems-02-14/003.png";
import * as React from 'react';
export default {
  Dice,
  CoinFlip,
  Title,
  Drawing,
  Image1,
  Image2,
  Image3,
  React
};